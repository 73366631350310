import React from "react";


const CallToAction = () => {
  return (
    <section className="relative w-full text-white">
      <div className="w-full lg:w-3/4 h-full absolute bottom-0 bg-gray-900 z-n10"></div>
      <div className="lg:w-full lg:absolute lg:bottom-0 lg:left-0 lg:h-2/3 bg-blue-dark z-n20"></div>
      <div className="py-16 lg:py-24 call-to-action-text">
        <div className="md:w-2/3">
          <h2 className="pb-8 text-3xl font-thin">How to Reach Us</h2>
          <p className="pb-8 text-lg font-thin">
            If you have a pressing legal issue, our lawyers can help:
          </p>
          <div className="flex flex-col md:flex-row md:items-start md:justify-between space-y-8 md:space-y-0">
            <div className="tracking-wider font-thin">
              <span className="block underline"><a href="mailto:contact@knd.law">contact@knd.law</a></span>
              <span className="block"><a href="tel:+14165373529">+1 416 537 3529</a></span>
            </div>
            <div>
              <span className="block font-thin">Suite 401, Yonge Eglinton Centre</span>
              <span className="block font-thin">2300 Yonge Street</span>
              <span className="block font-thin">Toronto, Ontario</span>
              <span className="block font-thin">Canada M4P 1E4</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction;