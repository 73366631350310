import React from "react";

import CallToAction from "../../components/CallToAction/CallToAction";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";


const CaseUpdatesPage = () => {
  return (
    <Layout
      title="Case Updates"
      description=""
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="Case Updates"
        colorScheme="light"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default CaseUpdatesPage;